import { render, staticRenderFns } from "./CardType2.vue?vue&type=template&id=0c19858a&scoped=true"
import script from "./CardType2.vue?vue&type=script&lang=js"
export * from "./CardType2.vue?vue&type=script&lang=js"
import style0 from "./CardType2.vue?vue&type=style&index=0&id=0c19858a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c19858a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/drone/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c19858a')) {
      api.createRecord('0c19858a', component.options)
    } else {
      api.reload('0c19858a', component.options)
    }
    module.hot.accept("./CardType2.vue?vue&type=template&id=0c19858a&scoped=true", function () {
      api.rerender('0c19858a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home/components/menu/CardType2.vue"
export default component.exports